<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Research interest <span class="text-danger">*</span></a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="mb-1">
              <label
                for=""
                class="form-label disp-bl"
              >Content <span class="text-danger float-end">{{ contentLength }}/1000</span></label>
              <div id="full-wrapper">
                <div id="full-container">
                  <div class="editor">
                    <quill-editor
                      ref="quillInterest"
                      v-model="user.research_interest"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      contentLength: 0,
    }
  },
  computed: {
    checkCharacterCounter() {
      return this.user.research_interest
    },
  },
  watch: {
    checkCharacterCounter() {
      this.$refs.quillInterest.quill.deleteText(1000, this.$refs.quillInterest.quill.getLength() - 1)
      this.contentLength = this.$refs.quillInterest.quill.getLength() - 1
      if (this.$refs.quillInterest.quill.getLength() - 1 === 0) {
        this.user.research_interest = null
      }
    },
  },
  mounted() {
    this.contentLength = this.$refs.quillInterest.quill.getLength() - 1
    if (this.$refs.quillInterest.quill.getLength() - 1 === 0) {
      this.user.research_interest = null
    }
  },
  methods: {
    textChanged($event) {
      if ($event.editor.getLength() > 1000) {
        $event.editor.deleteText(1000, $event.editor.getLength() - 1)
      }
    },
  },

}
</script>

<style>

</style>
