<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Edit ICREA website information - {{ item.convo ? item.convo.title : '' }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to professional background"
              @click="$router.push({name: 'awarded.awardees-list'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Research lines settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">ICREA website data</a>
                </h3>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <img
                        v-if="file_url"
                        height="100%"
                        width="100%"
                        class="img-fluid"
                        :alt="item.public_data.alternate_text"
                        :src="file_url"
                      >
                      <img
                        v-if="icon_path"
                        height="100%"
                        width="100%"
                        :alt="item.public_data.alternate_text"
                        :src="icon_path"
                      >
                    </div>
                    <div
                      class="mb-1 col-6"
                    >
                      <div
                        class="mb-1"
                      >
                        <div
                          class="input-group custom-file-button"
                        >
                          <label
                            class="input-group-text"
                            for="formFileImage"
                          >Select image</label>
                          <input
                            id="formFileImage"
                            :ref="`file-profile-image`"
                            class="form-control"
                            type="file"
                            @change="uploadFile('file-profile-image')"
                          >
                        </div>
                      </div>
                      <small
                        class="mt-1 mb-1"
                      >
                        Files must be less than <strong>2 MB</strong>.<br>
                        Allowed file types: <strong>png, gif, webp or jpg</strong>.
                      </small>
                      <div
                        class="mb-1 mt-1"
                      >
                        <label
                          for=""
                          class="form-label"
                        >Alternate text</label>
                        <input
                          v-model="item.public_data.alternate_text"
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <small>This text will be used by screen readers, search engines, or when the image cannot be loaded</small>
                      <div
                        class="mb-1 mt-1"
                      >
                        <label
                          for=""
                          class="form-label"
                        >Web site</label>
                        <input
                          v-model="item.public_data.website"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-6 card-body">
                      <h4>Resercher ID (Web of Science)</h4>
                      <div class="">
                        <label
                          for=""
                          class="form-label"
                        >Code</label>
                        <input
                          v-model="item.public_data.researcher_id"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-6 card-body">
                      <h4>ORCID</h4>
                      <div class="">
                        <label
                          for=""
                          class="form-label"
                        >Code</label>
                        <input
                          v-model="item.public_data.orcid"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-6 ms-0 ps-0 pe-0 mt-1">
                      <ShortBiography
                        v-if="!loading"
                        :user="item.public_data"
                      />
                    </div>
                    <div class="col-6 ms-0 ps-0 pe-0 mt-1">
                      <ResearchInterest
                        v-if="!loading"
                        :user="item.public_data"
                      />
                    </div>
                    <div class="col-6 ps-0 pe-0">
                      <MainERC
                        v-if="!loading"
                        :user="item.public_data"
                      />
                    </div>
                    <!-- <div class="col-6">
                      <ListOtherERC
                        v-if="!loading"
                        :user="item.public_data"
                      />
                    </div> -->
                    <div class="col-6 ps-0 pe-0">
                      <Keywords
                        v-if="!loading"
                        :user="item.public_data"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-actions">
        <button
          v-if="!loading"
          id="type-success"
          class="btn btn-success"
          @click="save"
        >
          <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
        </button>
        <button
          v-if="loading"
          class="btn btn-outline-success"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
          <span class="ms-25 align-middle">Saving...</span>
        </button>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import ShortBiography from '@/views/back/partials/components/profile/ShortBiography.vue'
import ResearchInterest from '@/views/back/partials/components/profile/ResearchInterest.vue'
import Keywords from '@/views/back/partials/components/profile/Keywords.vue'
import MainERC from '@/views/back/partials/components/profile/MainERC.vue'
import Vue from 'vue'
// import ListOtherERC from '@/views/back/partials/components/profile/ListOtherERC.vue'

export default {
  components: {
    ShortBiography,
    ResearchInterest,
    Keywords,
    MainERC,
    // ListOtherERC,
  },
  data() {
    return {
      blob_url: '',
      icon_path: '',
      file_url: '',
      awardedId: this.$route.params.id,
      loading: true,
      contentLengthBio: 0,
      contentLengthResearch: 0,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
      item: 'academyAwarded/item',
    }),
  },
  async mounted() {
    if (this.awardedId) {
      await this.$store.dispatch('academyAwarded/fetchUser', this.awardedId)
      if (this.item.public_data && this.item.public_data.image) {
        this.icon_path = this.item.public_data.image.url
      }
      this.loading = false
    }
  },
  methods: {
    async save() {
      this.loading = true

      try {
        const { image } = this.item.public_data
        await this.$store.dispatch('academyAwarded/savePublicData', { id: this.awardedId, data: this.item.public_data })
        if (image) {
          Vue.prototype.$saveFiles([image])
        }

        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The public data has been saved correctly!', '', 'success')
      }

      this.loading = false
    },
    async uploadFile() {
      const file = this.$refs['file-profile-image'].files[0]
      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: 'file-profile-image',
      }

      this.item.public_data.image = fileData
      this.file_url = URL.createObjectURL(file)

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
}
</script>
